import {useEffect, useState} from 'react';
import {useController} from 'react-hook-form';
import {isFunction, isArray} from 'lodash';
import {prop} from 'lodash/fp';

import {Controller} from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import MUITextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {CommonFieldProps} from '../types';
import {SelectFieldProps, SelectOptionType} from './types';
import {FormControl, InputLabel, Select} from '@mui/material';

const AutocompleteSelectField = ({
  options,
  name,
  label,
  multiple,
  autoCompleteOptions,
  disabled,
}: SelectFieldProps & CommonFieldProps & {options: SelectOptionType[]}) => {
  const {
    fieldState: {invalid},
    field: {value: fieldValue, onChange, onBlur},
  } = useController({name});
  const [value, setValue] = useState<SelectOptionType | SelectOptionType[] | null>(multiple ? [] : null);

  const onChangeAutocomplete = (_: any, option: SelectOptionType | SelectOptionType[] | null) => {
    onChange(isArray(option) ? option.map(i => i.value) : option?.value || '');
  };

  useEffect(() => {
    const newValue = multiple
      ? options.filter(item => fieldValue.find((i: string) => i === item.value))
      : options.find(item => item.value === fieldValue) || null;
    setValue(newValue);
  }, [fieldValue, setValue, options, multiple]);

  return (
    <Autocomplete
      options={options}
      value={value}
      multiple={multiple}
      onChange={onChangeAutocomplete}
      onBlur={onBlur}
      disabled={disabled}
      groupBy={autoCompleteOptions?.groupBy}
      getOptionLabel={prop(autoCompleteOptions?.optionLabel || 'name')}
      renderInput={params => <MUITextField {...params} error={invalid} label={label} />}
    />
  );
};

const CommonField = (props: SelectFieldProps & CommonFieldProps & {options: SelectOptionType[]}) => {
  if (props.variant === 'autocomplete') return <AutocompleteSelectField {...props} />;
  const {label, name, options, disabled, multiple} = props;

  return (
    <Controller
      name={name}
      render={fieldProps => (
        <FormControl fullWidth error={fieldProps.fieldState.invalid}>
          <InputLabel>{label}</InputLabel>
          <Select multiple={multiple} label={label} disabled={disabled} {...fieldProps.field}>
            <MenuItem value={undefined} />
            {options.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

const HookField = ({
  options: useOptions,
  ...props
}: SelectFieldProps & CommonFieldProps & {options: () => SelectOptionType[]}) => {
  const options = useOptions();
  return <CommonField {...props} options={options} />;
};

export const SelectField = (props: SelectFieldProps & CommonFieldProps) => {
  if (isFunction(props.options)) return <HookField {...(props as any)} />;
  return <CommonField {...(props as any)} />;
};
