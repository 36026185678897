import {useDispatch} from 'react-redux';
// import {getSagaExtension} from 'redux-dynamic-modules-saga';
// import {getThunkExtension} from 'redux-dynamic-modules-thunk';
// import {createStore, IExtension} from 'redux-dynamic-modules';
// import {combineReducers} from '@reduxjs/toolkit';
import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/dist/query';

import {adminApi, filesApi, gatewayApi, usersApi} from 'api';
import {authReducer} from 'modules/auth';
import {catchUnauthorizedResponseMiddleware} from './middlewares';

import createSagaMiddleware from 'redux-saga';
import {authSaga} from 'modules/auth/store/sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    // [authApi.reducerPath]: authApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [gatewayApi.reducerPath]: gatewayApi.reducer,
    ...authReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(usersApi.middleware)
      .concat(adminApi.middleware)
      // .concat(authApi.middleware)
      .concat(filesApi.middleware)
      .concat(gatewayApi.middleware)
      .concat(catchUnauthorizedResponseMiddleware)
      .concat(sagaMiddleware),
});
sagaMiddleware.run(authSaga);

// const getDefaultModule = (): IExtension => ({
//   middleware: [
//     adminApi.middleware,
//     authApi.middleware,
//     filesApi.middleware,
//     usersApi.middleware,
//     catchUnauthorizedResponseMiddleware,
//   ],
// });
//
// const initialState = {};
//
// export const store = createStore({
//   initialState,
//   extensions: [getDefaultModule(), getThunkExtension(), getSagaExtension()],
//   advancedCombineReducers: reducers =>
//     combineReducers({
//       [authApi.reducerPath]: authApi.reducer,
//       [adminApi.reducerPath]: adminApi.reducer,
//       [filesApi.reducerPath]: filesApi.reducer,
//       [usersApi.reducerPath]: usersApi.reducer,
//       ...reducers,
//     }),
// });
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
