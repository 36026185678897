import {FC, useState} from 'react';
import compose from 'lodash/fp/compose';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import {Icon} from '@iconify/react';
import EditFill from '@iconify/icons-eva/edit-2-fill';
import TrashIconFill from '@iconify/icons-eva/trash-2-fill';

import {adminApi} from 'api';

import useDialog from 'components/Dialog';
import useDialogSelector from 'components/DialogSelector';
import {widgetsVisualsColumns} from 'modules/widgets/columns';
import {WidgetVisualIdProp, WidgetVisualsIdProp} from 'modules/widgets/types';

import {WidgetVisualsStructure} from '../WidgetVisuals/WidgetVisualsStructure';
import {WidgetVisualForm} from '../WidgetVisualForm';

const WidgetVisualPreview: FC<WidgetVisualIdProp & {onRemove?: () => void; onEdit?: () => void}> = ({
  visualId,
  onRemove,
  onEdit,
}) => {
  const {data} = adminApi.endpoints.widgetsVisualIndex.useQuery(visualId ? {visualId} : skipToken);

  return (
    <Box p={3} position="relative" key={visualId}>
      {data?.name}
      <Box position="absolute" sx={{top: 0, right: 0}}>
        {onRemove && (
          <IconButton onClick={onRemove}>
            <Icon icon={TrashIconFill} />
          </IconButton>
        )}
        {onEdit && (
          <IconButton onClick={onEdit}>
            <Icon icon={EditFill} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export const WidgetVisualSelector: FC<WidgetVisualsIdProp & {onChange: (visualsId: string[]) => void}> = ({
  visualsId = [],
  onChange,
}) => {
  const [selectedVisualId, setSelectedVisualId] = useState<string | undefined>();

  const [Dialog, openDialog] = useDialog();
  const [FormDialog, openFormDialog, closeFormDialog] = useDialog();
  const {Selector: WidgetVisualsSelector, openSelector} = useDialogSelector();
  const onRemove = (visualId: string) => () => onChange(visualsId.filter(item => item === visualId));

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button onClick={openSelector} variant="contained">
          Добавить визуал
        </Button>
        <Button onClick={openFormDialog} variant="contained">
          Создать визуал
        </Button>
      </Stack>

      <Stack spacing={3} direction="row">
        {visualsId?.map(visualId => (
          <WidgetVisualPreview
            key={visualId}
            visualId={visualId}
            onRemove={onRemove(visualId)}
            onEdit={() => compose(openDialog, setSelectedVisualId)(visualId)}
          />
        ))}
      </Stack>
      <Dialog title="Редактирование виджета" maxWidth="lg">
        {selectedVisualId && <WidgetVisualsStructure visualId={selectedVisualId} />}
      </Dialog>

      <WidgetVisualsSelector
        api="admin"
        queryName="widgetsVisualsIndex"
        title="Выбор визуалов виджетов"
        onConfirm={onChange}
        initialSelection={visualsId}
        columns={widgetsVisualsColumns()}
      />
      <FormDialog title="Создать визуал виджета">
        <WidgetVisualForm onReset={closeFormDialog} onSuccess={visualId => [...visualsId, visualId]} />
      </FormDialog>
    </>
  );
};
