import {useMemo} from 'react';
import isArray from 'lodash/isArray';

import {APIList} from 'utils/api';
import {DEFAULT_LIMIT} from 'utils/constants';
import {APIKeys, EndpointName} from 'utils/api/types';

import {DataListProps} from './types';

export function useList<Query, Result>({
  useFetch,
  params,
  debug,
  getItems,
  disableLimit,
}: Pick<DataListProps<Query, Result>, 'useFetch' | 'params' | 'debug' | 'getItems' | 'disableLimit'>) {
  const actualParams = useMemo(() => {
    if (!disableLimit) return {...(params || {}), limit: DEFAULT_LIMIT};
    return params || {};
  }, [disableLimit, params]);
  const {data: response, isFetching, isLoading} = useFetch(actualParams as Query);

  // eslint-disable-next-line no-console
  if (debug && process.env.NODE_ENV !== 'production') console.log(response);
  const data = useMemo(
    () => (getItems && response ? getItems(response) : isArray(response) ? response : (response as any)?.data || []),
    [getItems, response]
  );
  return {data, isFetching, isLoading};
}

export function useIsQueryExists<Key extends APIKeys, Endpoint extends EndpointName<Key>>(
  api: Key,
  queryName: Endpoint
) {
  const fetchFn = APIList[api][queryName];
  if (fetchFn && 'useQuery' in fetchFn) return (fetchFn as any).useQuery;
  return null;
}

export default useList;
