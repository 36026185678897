import pick from 'lodash/pick';

import {WidgetsAttrs, WidgetAttrDto} from 'api/generated/admin-api';
import {yupNumber, yupObject, yupString, yupStringOfOptionsRequired, yupStringRequired} from 'utils/validation';

import {FieldStructure} from 'components/Form/types';
import {WidgetAttrFormDto} from 'modules/widgets/types';
import {templateListColumns, templateOptionsColumns} from 'modules/tasks/utils';

const formulaOptions: {value: WidgetAttrDto['formula']; name: string}[] = [
  {value: 'list', name: 'Список'},
  {value: 'first_value', name: 'Первое значение'},
  {value: 'last_value', name: 'Последнее значение'},
  {value: 'sum', name: 'Сумма значений'},
  {value: 'avg', name: 'Среднее значение'},
  {value: 'min', name: 'Минимальное значение'},
  {value: 'max', name: 'Максимальное значение'},
  {value: 'increment', name: 'Инкремент'},
  {value: 'delta', name: 'Дельта'},
];

export const attributeFormulaSelect: FieldStructure<Pick<WidgetAttrFormDto, 'formula'>>[] = [
  {
    type: 'select',
    name: 'formula',
    label: 'Формула',
    options: formulaOptions,
  },
];

export const fields = (attr?: WidgetsAttrs): FieldStructure<WidgetAttrFormDto>[] => [
  {
    direction: Boolean(attr) ? 'row' : 'column',
    fields: [
      {
        type: 'text',
        name: 'name',
        label: 'Название',
      },
      ...attributeFormulaSelect,
      {
        type: 'dialogSelect',
        name: 'templateId',
        label: 'Шаблон',
        getName: item => item.name,
        api: 'admin',
        queryName: 'templatesIndex',
        params: {},
        columns: templateListColumns(),
      },
      {
        showIf: 'templateId',
        type: 'dialogSelect',
        name: 'templateOptionId',
        getName: item => item.name,
        label: 'Вариант ответа',
        api: 'admin',
        queryName: 'templateOptionsIndex',
        params: (params: any) => ({
          templateId: params.templateId,
        }),
        columns: templateOptionsColumns(),
      },
    ],
  },
];

const initialValues: WidgetAttrFormDto = {
  name: '',
  templateId: '',
  formula: 'first_value',
  positionNumber: 0,
  templateOptionId: '',
};

export const initials = (attr?: WidgetsAttrs): WidgetAttrFormDto =>
  attr ? (pick(attr, Object.keys(initialValues)) as WidgetAttrFormDto) : initialValues;

export const validation = yupObject({
  name: yupStringRequired,
  formula: yupStringOfOptionsRequired(formulaOptions),
  positionNumber: yupNumber,
  templateId: yupStringRequired,
  templateOptionId: yupString,
});
