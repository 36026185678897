import {useState, ReactNode} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import AuthLogin from 'modules/auth/containers/AuthLogin';
import {useAuth0} from '@auth0/auth0-react';
import LoadingScreen from '__theme/components/LoadingScreen';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const {pathname} = useLocation();
  const {isAuthenticated, isLoading} = useAuth0();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (isLoading) return <LoadingScreen />;
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <AuthLogin />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
