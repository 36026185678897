import {concat, uniq} from 'lodash';
import {TagDescription} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

// import {api as rawAuthApi} from './generated/auth-api';
import {api as rawAdminApi} from './generated/admin-api';
import {api as rawFilesApi} from './generated/files-api';
import {api as rawUsersApi} from './generated/users-api';
import {api as rawGatewayApi} from './generated/gateway-api';

const getTag = <T extends string>(tagName: T, value?: string) =>
  ({type: tagName, id: value || '@@LIST'} as TagDescription<T>);

const getListTags =
  <Item>(tagName: string, getId: (item: Item) => string) =>
  (data?: {total: number; data?: Array<Item>}) => {
    const common = getTag(tagName);
    if (!data) return [common];
    return (data.data || []).map(item => getTag(tagName, getId(item))).concat([common]);
  };

export const gatewayApi = rawGatewayApi.enhanceEndpoints({
  addTagTypes: ['Tags'],
  endpoints: {
    tagsIndex: {
      providesTags: getListTags('Tags', (item: any) => item._id) as any,
    },
    tagsAdd: {
      invalidatesTags: () => [{type: 'Tags', id: '@@LIST'}],
    },
    tagPatch: {
      invalidatesTags: (_, __, {tagId}) => [{type: 'Tags', id: tagId}],
    },
  },
});

export const adminApi = rawAdminApi.enhanceEndpoints({
  addTagTypes: [
    'Mailings',
    'Tags',
    'Users',
    'UsersPermissions',
    'UserLogins',
    'Groups',
    'GroupUsers',
    'Contents',
    'ContentTags',
    'ContentsCategories',
    'Scripts',
    'Tasks',
    'TasksBlocks',
    'TasksBlocksTasks',
    'TasksTimelines',
    'ScriptGroups',
    'ScriptActions',
    'Templates',
    'TemplateOptions',
    'StreamUsers',
    'Collectors',
    'CollectorsKeys',
    'TasksCategories',
    'Settings',
    'Quests',
    'QuestTags',
    'QuestPoints',
    'QuestsTimelines',
    'QuestPointsRewards',
    'QuestPointsTimelines',
    'Claims',
    'ClaimsData',
    'Blockers',
    'Access',
    'ReactionsBlocks',
    'PaymentsServices',
    'Rewards',
    'Widgets',
    'WidgetAttrs',
    'WidgetRules',
    'WidgetVisuals',
    'WidgetVisualsProps',
    'WidgetVisualsDesign',
  ],
  endpoints: {
    // WIDGETS
    widgetsIndex: {
      providesTags: data =>
        concat(
          [{type: 'Widgets', id: 'list'}],
          (data?.data || []).map(item => ({type: 'Widgets', id: `Widget.${item?._id}`}))
        ),
    },
    widgetsAdd: {
      invalidatesTags: [{type: 'Widgets', id: 'list'}],
    },
    widgetIndex: {
      providesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    widgetUpdate: {
      invalidatesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    widgetPatch: {
      invalidatesTags: data => [{type: 'Widgets', id: `Widget.${data?._id}`}],
    },
    // WIDGET ATTRS
    widgetAttrsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetAttrs', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetAttrs', id: `WidgetAttr.${item?._id}`}))
        ),
    },
    widgetAttrsAdd: {
      invalidatesTags: [{type: 'WidgetAttrs', id: 'list'}],
    },
    widgetAttrsChangePositions: {
      invalidatesTags: [{type: 'WidgetAttrs', id: `list`}],
    },
    widgetAttrIndex: {
      providesTags: data => [{type: 'WidgetAttrs', id: `WidgetAttr.${data?._id}`}],
    },
    widgetAttrUpdate: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `WidgetAttr.${data?._id}`}],
    },
    widgetAttrPatch: {
      invalidatesTags: data => [{type: 'WidgetAttrs', id: `WidgetAttr.${data?._id}`}],
    },
    // WIDGET RULES
    widgetRulesIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetRules', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetRules', id: `WidgetRule.${item?._id}`}))
        ),
    },
    widgetRulesAdd: {
      invalidatesTags: [{type: 'WidgetRules', id: 'list'}],
    },
    widgetRuleIndex: {
      providesTags: data => [{type: 'WidgetRules', id: `WidgetRule.${data?._id}`}],
    },
    widgetRuleUpdate: {
      invalidatesTags: data => [{type: 'WidgetRules', id: `WidgetRule.${data?._id}`}],
    },
    widgetRulePatch: {
      invalidatesTags: data => [{type: 'WidgetRules', id: `WidgetRule.${data?._id}`}],
    },
    // WIDGET VISUALS
    widgetsVisualsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisuals', id: 'list'}],
          (data?.data || []).map(item => ({type: 'WidgetVisuals', id: `WidgetVisual.${item?._id}`}))
        ),
    },
    widgetsVisualIndex: {
      providesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    widgetsVisualsAdd: {
      invalidatesTags: [{type: 'WidgetVisuals', id: 'list'}],
    },
    widgetsVisualUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    widgetsVisualPatch: {
      invalidatesTags: data => [{type: 'WidgetVisuals', id: `WidgetVisual.${data?._id}`}],
    },
    // WIDGET VISUALS PROPS
    widgetsVisualPropsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisualsProps', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${item?._id}`}))
        ),
    },
    widgetsVisualPropsAdd: {
      invalidatesTags: [{type: 'WidgetVisualsProps', id: `list`}],
    },
    widgetsVisualPropsChangePositions: {
      invalidatesTags: [{type: 'WidgetVisualsProps', id: `list`}],
    },
    widgetsVisualPropIndex: {
      providesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropPatch: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    widgetsVisualPropDelete: {
      invalidatesTags: data => [{type: 'WidgetVisualsProps', id: `WidgetVisualsProp.${data?._id}`}],
    },
    // WIDGET VISUALS DESIGN
    widgetsVisualDesignsIndex: {
      providesTags: data =>
        concat(
          [{type: 'WidgetVisualsDesign', id: 'list'}],
          (data || []).map(item => ({type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${item?._id}`}))
        ),
    },
    widgetsVisualDesignsAdd: {
      invalidatesTags: [{type: 'WidgetVisualsDesign', id: `list`}],
    },
    widgetsVisualDesignsChangePositions: {
      invalidatesTags: [{type: 'WidgetVisualsDesign', id: `list`}],
    },
    widgetsVisualDesignIndex: {
      providesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignUpdate: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignPatch: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    widgetsVisualDesignDelete: {
      invalidatesTags: data => [{type: 'WidgetVisualsDesign', id: `WidgetVisualsDesign.${data?._id}`}],
    },
    // REWARDS
    rewardsIndex: {
      providesTags: data =>
        concat(
          [{type: 'Rewards', id: 'list'}],
          (data?.data || []).map(item => ({type: 'Rewards', id: `Reward.${item?._id}`}))
        ),
    },
    rewardsAdd: {
      invalidatesTags: [{type: 'Rewards', id: 'list'}],
    },
    rewardIndex: {
      providesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    rewardUpdate: {
      invalidatesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    rewardPatch: {
      invalidatesTags: data => [{type: 'Rewards', id: `Reward.${data?._id}`}],
    },
    // PAYMENTS SERVICES
    paymentsServicesIndex: {
      providesTags: data =>
        concat(['PaymentsServices'], data?.data?.map(item => ({type: 'PaymentsServices', id: item._id})) as any) as any,
    },
    paymentsServicesAdd: {
      invalidatesTags: data => ['PaymentsServices', {type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceIndex: {
      providesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServicePatch: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceUpdate: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    paymentsServiceDelete: {
      invalidatesTags: data => [{type: 'PaymentsServices', id: data?._id}],
    },
    // CLAIMS
    claimsDataIndex: {
      providesTags: () => [{type: 'Claims', id: 'list'}],
    },
    claimsDataDetails: {
      providesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
      ],
    },
    claimsDataSetClaimed: {
      invalidatesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
        {type: 'Claims', id: 'list'},
      ],
    },
    claimsDataRejectClaims: {
      invalidatesTags: (_, __, {referenceId, referenceName}) => [
        {type: 'ClaimsData', id: `${referenceName}.${referenceId}`},
        {type: 'Claims', id: 'list'},
      ],
    },
    // ACCESS
    accessIndex: {
      providesTags: () => [{type: 'Access', id: 'list'}],
    },
    accessAdd: {
      invalidatesTags: () => [{type: 'Access', id: 'list'}],
    },
    accessElemIndex: {
      providesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    accessElemPatch: {
      invalidatesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    accessElemUpdate: {
      invalidatesTags: (_, __, {accessId}) => [{type: 'Access', id: accessId}],
    },
    // BLOCKERS
    blockersIndex: {
      providesTags: () => [{type: 'Blockers', id: 'list'}],
    },
    blockerIndex: {
      providesTags: data => [{type: 'Blockers', id: data?._id}],
    },
    blockersAdd: {
      invalidatesTags: () => [{type: 'Blockers', id: 'list'}],
    },
    blockerUpdate: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    blockerPatch: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    blockerDelete: {
      invalidatesTags: (_, __, {blockerId}) => [
        {type: 'Blockers', id: 'list'},
        {type: 'Blockers', id: blockerId},
      ],
    },
    // QUEST TAGS
    questTagsSet: {
      invalidatesTags: (_, __, {questId}) => [{type: 'QuestTags', id: questId}],
    },
    questTagsIndex: {
      providesTags: (_, __, {questId}) => [{type: 'QuestTags', id: questId}],
    },
    // QUEST REWARDS
    questPointsRewardsIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointsRewardsAdd: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointRewardUpdate: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    questPointRewardPatch: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsRewards', id: pointId}],
    },
    // QUEST POINT TIMELINES
    questPointsTimelinesIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPointsTimelines', id: pointId}],
    },
    questPointsTimelinesAdd: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPointsTimelines', id: pointId}],
    },
    // QUEST TIMELINE
    questTimelineElemRecalculateRewards: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemEmitSpecific: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemReset: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemDelete: {
      invalidatesTags: (data, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemEmit: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemStop: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemStart: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemRebuild: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemPatch: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemUpdate: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelineElemIndex: {
      providesTags: (_, __, {timelineId}) => [{type: 'QuestsTimelines', id: `timeline.${timelineId}`}],
    },
    questTimelinesIndex: {
      providesTags: (data, __, {questId}) =>
        concat(
          [{type: 'QuestsTimelines', id: questId}],
          (data || []).map(item => ({type: 'QuestsTimelines', id: `timeline.${item._id}`}))
        ),
    },
    questTimelinesAdd: {
      invalidatesTags: (_, __, {questId}) => [{type: 'QuestsTimelines', id: questId}],
    },
    // QUEST POINTS
    questPointIndex: {
      providesTags: (_, __, {pointId}) => [{type: 'QuestPoints', id: pointId}],
    },
    questPointPatch: {
      invalidatesTags: (data, __, {pointId}) => [{type: 'QuestPoints', id: pointId}],
    },
    questPointUpdate: {
      invalidatesTags: (_, __, {pointId}) => [{type: 'QuestPoints', id: pointId}],
    },
    // EVENTS
    questIndex: {
      providesTags: data => [{type: 'Quests', id: data?._id}],
    },
    questPatch: {
      invalidatesTags: (_, __, {questId}) => [{type: 'Quests', id: questId}],
    },
    questPointsIndex: {
      providesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
    },
    questPointsAdd: {
      invalidatesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
    },
    questPointsChangePositions: {
      invalidatesTags: (data, meta, {questId}) => [{type: 'QuestPoints', id: `quest.${questId}`}],
    },
    // MAILINGS
    mailingsAdd: {
      invalidatesTags: ['Mailings'],
    },
    mailingActivate: {
      invalidatesTags: ['Mailings'],
    },
    mailingDisactivate: {
      invalidatesTags: ['Mailings'],
    },
    mailingUpdate: {
      invalidatesTags: (_, __, {mailingId}) => [{type: 'Mailings', id: mailingId}],
    },
    mailingIndex: {
      providesTags: (_, __, {mailingId}) => [{type: 'Mailings', id: mailingId}],
    },
    mailingsIndex: {
      providesTags: () => ['Mailings'],
    },
    // SETTINGS
    settingsIndex: {
      providesTags: ['Settings'],
    },
    settingsUpdate: {
      invalidatesTags: ['Settings'],
    },
    settingsPatch: {
      invalidatesTags: ['Settings'],
    },
    // TASK CATEGORIS
    tasksCategoriesIndex: {
      providesTags: getListTags('TasksCategories', (item: any) => item._id) as any,
    },
    tasksCategoriesAdd: {
      invalidatesTags: [{type: 'TasksCategories', id: '@@LIST'}],
    },
    tasksCategoryPatch: {
      invalidatesTags: (_, __, {categoryId}) => [{type: 'TasksCategories', id: categoryId}],
    },
    tasksCategoryUpdate: {
      invalidatesTags: (_, __, {categoryId}) => [{type: 'TasksCategories', id: categoryId}],
    },
    // COLLECTOR KEYS
    collectorKeysIndex: {
      providesTags: getListTags('CollectorsKeys', (item: any) => item._id) as any,
    },
    collectorKeysAdd: {
      invalidatesTags: [{type: 'CollectorsKeys', id: '@@LIST'}],
    },
    collectorKeyUpdate: {
      invalidatesTags: (_, __, {keyId}) => [{type: 'CollectorsKeys', id: keyId}],
    },
    // COLLECTORS
    collectorsIndex: {
      providesTags: getListTags('Collectors', (item: any) => item._id) as any,
    },
    collectorsAdd: {
      invalidatesTags: [{type: 'Collectors', id: '@@LIST'}],
    },
    collectorUpdate: {
      invalidatesTags: (_, __, {collectorId}) => [{type: 'Collectors', id: collectorId}],
    },
    // TASK ACCESS SETTING
    taskTimelinesIndex: {
      providesTags: getListTags('TasksTimelines', (item: any) => item._id) as any,
    },
    taskTimelinesAdd: {
      invalidatesTags: [{type: 'TasksTimelines', id: '@@LIST'}],
    },
    taskTimelineElemDelete: {
      invalidatesTags: [{type: 'TasksTimelines', id: '@@LIST'}],
    },
    taskTimelineElemPatch: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'TasksTimelines', id: timelineId}],
    },
    taskTimelineElemUpdate: {
      invalidatesTags: (_, __, {timelineId}) => [{type: 'TasksTimelines', id: timelineId}],
    },
    streamUsers: {
      providesTags: data => {
        if (!data?.length) return [{type: 'StreamUsers', id: '@@LIST'}];
        return uniq(data.map(item => item.streamId)).map(item => ({type: 'StreamUsers', id: item}));
      },
    },
    streamAddUsers: {
      invalidatesTags: (_, __, {streamId}) => [{type: 'StreamUsers', id: streamId}],
    },
    streamDeleteUser: {
      invalidatesTags: (_, __, {streamId}) => [{type: 'StreamUsers', id: streamId}],
    },
    // TASKS BLOCK
    blockTasksIndex: {
      providesTags: ['TasksBlocksTasks'],
    },
    blockTaskAdd: {
      invalidatesTags: ['TasksBlocksTasks'],
    },
    blockTaskDeleteTask: {
      invalidatesTags: ['TasksBlocksTasks'],
    },
    //
    blocksIndex: {
      providesTags: ['TasksBlocks'],
    },
    blocksAdd: {
      invalidatesTags: ['TasksBlocks'],
    },
    blockIndex: {
      providesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    blockPatch: {
      invalidatesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    blockUpdate: {
      invalidatesTags: (_, __, {blockId}) => [{type: 'TasksBlocks', id: blockId}],
    },
    // TASKS
    taskIndex: {
      providesTags: data => [{type: 'Tasks', id: data?._id}],
    },
    taskUpdate: {
      invalidatesTags: (_, __, {taskId}) => [{type: 'Tasks', id: taskId}],
    },
    taskPatch: {
      invalidatesTags: (_, __, {taskId}) => [{type: 'Tasks', id: taskId}],
    },
    scriptActionsChangePositions: {
      invalidatesTags: () => [{type: 'ScriptActions', id: '@@LIST'}],
    },
    scriptActionsIndex: {
      providesTags: getListTags('ScriptActions', (item: any) => item._id) as any,
    },
    scriptActionsAdd: {
      invalidatesTags: () => [{type: 'ScriptActions', id: '@@LIST'}],
    },
    scriptActionPatch: {
      invalidatesTags: () => [{type: 'ScriptActions', id: '@@LIST'}],
    },
    templateIndex: {
      providesTags: (_, __, {templateId}) => [{type: 'Templates' as const, id: templateId}],
    },
    templateUpdate: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'Templates', id: templateId}],
    },
    templatePatch: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'Templates', id: templateId}],
    },
    templateOptionsIndex: {
      providesTags: data =>
        uniq((data || [])?.map(item => item.templateId)).map(item => ({type: 'TemplateOptions', id: item})),
    },
    templateOptionsAdd: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'TemplateOptions', id: templateId}],
    },
    templateOptionsChangePositions: {
      invalidatesTags: (_, __, {templateId}) => [{type: 'TemplateOptions', id: templateId}],
    },
    templateOptionIndex: {
      providesTags: data => [{type: 'TemplateOptions', id: data?.templateId || '@@LIST'}],
    },
    templateOptionPatch: {
      invalidatesTags: data => [{type: 'TemplateOptions', id: (data?.templateId as string) || '@@LIST'}],
    },
    templateOptionUpdate: {
      invalidatesTags: data => [{type: 'TemplateOptions', id: (data?.templateId as string) || '@@LIST'}],
    },
    scriptGroupsIndex: {
      providesTags: getListTags('ScriptGroups', (item: any) => item._id) as any,
    },
    scriptGroupsAdd: {
      invalidatesTags: () => [{type: 'ScriptGroups', id: '@@LIST'}],
    },
    scriptGroupIndex: {
      providesTags: data => [{type: 'ScriptGroups', id: data?._id}],
    },
    scriptGroupUpdate: {
      invalidatesTags: () => [{type: 'ScriptGroups', id: '@@LIST'}],
    },
    // SCRIPTS
    scriptsIndex: {
      providesTags: getListTags('Scripts', (item: any) => item._id) as any,
    },
    scriptsAdd: {
      invalidatesTags: () => [{type: 'Scripts', id: '@@LIST'}],
    },
    scriptIndex: {
      providesTags: data => [{type: 'Scripts', id: data?._id || '@@LIST'}],
    },
    scriptUpdate: {
      invalidatesTags: (_, __, {scriptId}) => [{type: 'Scripts', id: scriptId}],
    },
    scriptPatch: {
      invalidatesTags: (_, __, {scriptId}) => [{type: 'Scripts', id: scriptId}],
    },
    // CONTENTS
    contentsIndex: {
      providesTags: getListTags('Contents', (item: any) => item._id) as any,
    },
    contentsAdd: {
      invalidatesTags: () => [{type: 'Contents', id: '@@LIST'}],
    },
    contentIndex: {
      providesTags: data => [{type: 'Contents', id: data?._id}],
    },
    contentUpdate: {
      invalidatesTags: (_, __, {contentId}) => [{type: 'Contents', id: contentId}],
    },
    contentPatch: {
      invalidatesTags: (_, __, {contentId}) => [{type: 'Contents', id: contentId}],
    },
    // CONTENT TAGS
    contentTagsSet: {
      invalidatesTags: (_, __, {contentId}) => [{type: 'ContentTags', id: contentId}],
    },
    contentTagsIndex: {
      providesTags: (_, __, {contentId}) => [{type: 'ContentTags', id: contentId}],
    },
    // CONTENT CATEGORIES
    contentsCategoriesIndex: {
      providesTags: () => ['ContentsCategories'],
    },
    contentsCategoriesAdd: {
      invalidatesTags: () => ['ContentsCategories'],
    },
    contentsCategoryUpdate: {
      invalidatesTags: () => ['ContentsCategories'],
    },
    // GROUPS
    groupsIndex: {
      providesTags: getListTags('Groups', (item: any) => item._id) as any,
    },
    groupsAdd: {
      invalidatesTags: () => [{type: 'Groups', id: '@@LIST'}],
    },
    groupUpdate: {
      invalidatesTags: (_, __, {groupId}) => [{type: 'Groups', id: groupId}],
    },
    groupUsers: {
      providesTags: getListTags('GroupUsers', (item: any) => item._id) as any,
    },
    groupAddUsers: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    groupDeleteUser: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    groupSetLeader: {
      invalidatesTags: [{type: 'GroupUsers', id: '@@LIST'}],
    },
    userLoginsAdd: {
      invalidatesTags: () => [{type: 'UserLogins', id: '@@LIST'}],
    },
    userLoginUpdate: {
      invalidatesTags: (_, __, {loginId}) => [{type: 'UserLogins', id: loginId}],
    },
    userLoginsIndex: {
      providesTags: getListTags('UserLogins', (item: any) => item._id) as any,
    },
    usersIndex: {
      providesTags: getListTags('Users', (item: any) => item._id) as any,
    },
    userIndex: {
      providesTags: data => [{type: 'Users', id: data?._id}],
    },
    userRestore: {
      invalidatesTags: (data, _, {userId}) => [{type: 'Users', id: userId}],
    },
    usersAdd: {
      invalidatesTags: () => [{type: 'Users', id: '@@LIST'}],
    },
    userUpdate: {
      invalidatesTags: (_, __, {userId}) => [{type: 'Users', id: userId}],
    },
    // PERMISSIONS
    userPermissionsIndex: {
      providesTags: (_, __, {userId}) => [{type: 'UsersPermissions', id: userId}],
    },
    userPermissionsUpdate: {
      invalidatesTags: (_, __, {userId}) => [{type: 'UsersPermissions', id: userId}],
    },
    // TAGS
    tagsIndex: {
      providesTags: getListTags('Tags', (item: any) => item._id) as any,
    },
    tagsAdd: {
      invalidatesTags: () => [{type: 'Tags', id: '@@LIST'}],
    },
    tagUpdate: {
      invalidatesTags: (_, __, {tagId}) => [{type: 'Tags', id: tagId}],
    },
    // REACTIONS BLOCK
    reactionsBlocksIndex: {
      providesTags: ['ReactionsBlocks'],
    },
    reactionsBlockIndex: {
      providesTags: ['ReactionsBlocks'],
    },
    reactionsBlocksAdd: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockUpdate: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockPatch: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
    reactionsBlockDelete: {
      invalidatesTags: [{type: 'ReactionsBlocks', id: '@@LIST'}],
    },
  },
});

export const {useTagUpdateMutation, useTagsAddMutation} = adminApi;

// export const authApi = rawAuthApi.enhanceEndpoints({});
export const usersApi = rawUsersApi.enhanceEndpoints({
  addTagTypes: ['Goals', 'Publications', 'Comments'],
  endpoints: {
    // PUBLICATIONS
    publicationsIndex: {
      providesTags: getListTags('Publications', (item: any) => item._id) as any,
    },
    publicationIndex: {
      providesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
    },
    publicationPatch: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
    },
    publicationSafeDelete: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.user) {
            const {_id: userId, stats} = result.data.user;
            const streamId = stats?.streamsId || [];
            dispatch(
              usersApi.util.prefetch(
                'publicationsIndex',
                {userId, streamId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('publicationsIndex', {userId, streamId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    publicationSafeRestore: {
      invalidatesTags: (_, __, {postId}) => [{type: 'Publications', id: postId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.user) {
            const {_id: userId, stats} = result.data.user;
            const streamId = stats?.streamsId || [];
            dispatch(
              usersApi.util.prefetch(
                'publicationsIndex',
                {userId, streamId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('publicationsIndex', {userId, streamId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    // GOALS
    goalsIndex: {
      providesTags: getListTags('Goals', (item: any) => item._id) as any,
    },
    goalIndex: {
      providesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
    },
    goalPatch: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
    },
    goalSafeDelete: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.userId) {
            dispatch(
              usersApi.util.prefetch(
                'goalsIndex',
                {userId: result.data.userId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('goalsIndex', {userId: result.data.userId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    goalSafeRestore: {
      invalidatesTags: (_, __, {goalId}) => [{type: 'Goals', id: goalId}],
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        try {
          const result = await queryFulfilled;
          if (result.data.userId) {
            dispatch(
              usersApi.util.prefetch(
                'goalsIndex',
                {userId: result.data.userId, sort: 'created_date', isDeleted: true},
                {force: true}
              )
            );
            dispatch(
              usersApi.util.prefetch('goalsIndex', {userId: result.data.userId, sort: 'created_date'}, {force: true})
            );
          }
        } catch {}
      },
    },
    // COMMENTS
    commentsIndex: {
      providesTags: getListTags('Comments', (item: any) => item._id) as any,
    },
    commentIndex: {
      providesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
    commentSafeDelete: {
      invalidatesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
    commentSafeRestore: {
      invalidatesTags: (_, __, {commentId}) => [{type: 'Comments', id: commentId}],
    },
  },
});
export const filesApi = rawFilesApi.enhanceEndpoints({});
