/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from 'react';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {Dialog, DialogTitle, DialogContent} from '@mui/material';

type DialogFormProps = {
  Form: (props: {onClose: () => void; entryId?: string}) => JSX.Element;
};

export const useDialogForm = ({
  entityName,
  editTitle,
  createTitle,
}: {
  entityName: string;
  editTitle?: string;
  createTitle?: string;
}): [(props: DialogFormProps) => JSX.Element, () => void, (userId: string) => void] => {
  const createParam = `create${entityName}`;
  const editParam = `edit${entityName}`;
  const [query, setQuery] = useQueryParams({[createParam]: BooleanParam, [editParam]: StringParam});

  const open = useCallback(() => setQuery({[createParam]: true}, 'replaceIn'), []);
  const edit = useCallback((entryId: string) => setQuery({[editParam]: entryId}, 'replaceIn'), []);
  const close = useCallback(() => setQuery({[createParam]: undefined, [editParam]: undefined}, 'replaceIn'), []);

  const FormContainer = useCallback(
    ({Form}: DialogFormProps) => (
      <Dialog open={Boolean(query[createParam]) || Boolean(query[editParam])} maxWidth="sm" fullWidth onClose={close}>
        {(editTitle || createTitle) && <DialogTitle>{query[editParam] ? editTitle : createTitle}</DialogTitle>}
        <DialogContent dividers>
          <Form entryId={query[editParam] as string | undefined} onClose={close} />
        </DialogContent>
      </Dialog>
    ),
    [query, close]
  );

  return [FormContainer, open, edit];
};

export default useDialogForm;
