import {ReactNode} from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  TypographyProps,
} from '@mui/material';

import {DataTableHead} from './DataTableHead';
import {useDataTablePaginationProps, useDataTableSort} from './hooks';

type DataTableProps<T> = {
  title?: string;
  titleProps?: TypographyProps;
  columns?: {id: string; label?: string; alignRight?: boolean}[];
  data: T[];
  Row: ({item}: {item: T}) => JSX.Element;
  Toolbar?: ReactNode;
  withoutPagination?: boolean;
  paginationOptions?: {rowsPerPageOptions?: number[]; rowsPerPageDefault?: number};
  filters?: ((arg: T) => boolean)[];
};

export const DataTable = <T extends {_id?: string; deleteAt?: string}>({
  title,
  titleProps,
  data,
  columns,
  Toolbar,
  Row,
  withoutPagination,
  paginationOptions,
  filters,
}: DataTableProps<T>) => {
  const {paginationProps, applyPageFilter} = useDataTablePaginationProps(paginationOptions);
  const {applyFilters, onRequestSort, orderBy, order} = useDataTableSort<T>(filters);
  const rows = withoutPagination ? applyFilters(data) : applyPageFilter(applyFilters(data));

  return (
    <Stack borderRadius={2} sx={{boxShadow: theme => theme.customShadows.z16}}>
      {title && (
        <Typography variant="h6" p={3} {...titleProps}>
          {title}
        </Typography>
      )}
      {Toolbar}
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}}>
          {columns && <DataTableHead orderBy={orderBy} order={order} onRequestSort={onRequestSort} columns={columns} />}
          <TableBody>
            {rows.map(i => (
              <Row key={`${i?._id}${i?.deleteAt}`} item={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!withoutPagination && <TablePagination count={data.length} {...paginationProps} />}
    </Stack>
  );
};
