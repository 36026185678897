import {useController} from 'react-hook-form';
import Box from '@mui/system/Box';
import {UploadMultiFile, UploadSingleFile} from '__theme/components/upload';

import {useFileUpload} from './hooks';
import {FileUploadProps} from './types';
import {CommonFieldProps} from '../types';

export const FileUpload = (props: CommonFieldProps & FileUploadProps) => {
  const {onDrop, files, removeAll} = useFileUpload(props);
  const {label, multiple, name} = props;
  const {
    fieldState: {error},
  } = useController({name});
  return (
    <Box>
      <Box sx={{typography: 'body1', mb: 2}}>{label}</Box>
      {!multiple && <UploadSingleFile error={Boolean(error)} onDrop={onDrop} file={files[0]} accept="image/*" />}
      {multiple && (
        <UploadMultiFile
          onDrop={onDrop}
          error={Boolean(error)}
          files={files}
          showPreview
          accept="image/*"
          onRemove={() => {}}
          onRemoveAll={removeAll}
        />
      )}
    </Box>
  );
};

export default FileUpload;
