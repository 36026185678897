import {callRTK} from 'utils';
import {adminApi, gatewayApi} from 'api';

import {all} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {takeLatest, put, call} from 'typed-redux-saga';

import {actions} from './reducer';
import {destroySession, restoreSession, startSesstion} from './actions';

const getCurrentUser = gatewayApi.endpoints.profileIndex;
const getAppRoutes = adminApi.endpoints.appRoutes;

const getSessionLocalInfo = async (itemName: string) => {
  const value = (await localStorage.getItem(itemName)) || '';
  return value;
};
const removeSessionLocalInfo = async (itemName: string) => {
  await localStorage.removeItem(itemName);
};
const setSessionLocalInfo = async (itemName: string, value: string) => {
  await localStorage.setItem(itemName, value);
};

function* restoreSessionSaga() {
  const authToken = yield* call(getSessionLocalInfo, 'game_access_token');
  if (authToken) {
    const {data: user, isSuccess} = yield* callRTK(getCurrentUser, undefined);
    yield* callRTK(getAppRoutes, undefined);

    if (isSuccess && user) {
      yield put(actions.setIsAuth(true));
      yield put(actions.setUser(user));
    } else yield put(actions.setIsAuth(false));
  } else yield put(actions.setIsAuth(false));
}

function* destroySessionSaga() {
  yield call(removeSessionLocalInfo, 'game_access_token');
  yield put(actions.setIsAuth(false));
  yield put(actions.setUser(null));
}

function* startSesstionSaga({payload}: PayloadAction<string>) {
  yield call(setSessionLocalInfo, 'game_access_token', payload);
  yield put(restoreSession());
}

export function* authSaga() {
  yield all([
    takeLatest(restoreSession, restoreSessionSaga),
    takeLatest(destroySession, destroySessionSaga),
    takeLatest(startSesstion, startSesstionSaga),
  ]);
}
