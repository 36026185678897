import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Auth0Provider} from '@auth0/auth0-react';

import {store} from 'store';
import {AuthProvider} from 'modules/auth';
import {AppRouter} from 'router/AppRouter';

import ThemeConfig from './__theme/theme';
import Settings from './__theme/components/settings';
import GlobalStyles from './__theme/theme/globalStyles';
import ScrollToTop from './__theme/components/ScrollToTop';
import {ProgressBarStyle} from './__theme/components/LoadingScreen';
import ThemePrimaryColor from '__theme/components/ThemePrimaryColor';
import ThemeLocalization from '__theme/components/ThemeLocalization';
import NotistackProvider from './__theme/components/NotistackProvider';
import {BaseOptionChartStyle} from './__theme/components/charts/BaseOptionChart';

import {auth0config} from 'modules/auth/utils';
import {Lightbox} from 'components/Dialog';
import {ConfirmationDialog} from 'components/Dialog/useConfirmationDialog';

function App() {
  return (
    <Provider store={store}>
      <Auth0Provider {...auth0config}>
        <BrowserRouter>
          <ThemeConfig>
            <ThemePrimaryColor>
              <ThemeLocalization>
                <AuthProvider>
                  <NotistackProvider>
                    <BaseOptionChartStyle />
                    <Settings />
                    <ScrollToTop />
                    <GlobalStyles />
                    <ProgressBarStyle />
                    <Lightbox />
                    <AppRouter />
                    <ConfirmationDialog />
                  </NotistackProvider>
                </AuthProvider>
              </ThemeLocalization>
            </ThemePrimaryColor>
          </ThemeConfig>
        </BrowserRouter>
      </Auth0Provider>
    </Provider>
  );
}

export default App;
