import {Icon} from '@iconify/react';
import {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {alpha} from '@mui/material/styles';
import {Button, Box, Divider, MenuItem, Typography} from '@mui/material';

import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// routes
// import {PATH_DASHBOARD} from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import {MIconButton} from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

// auth module
import {destroySession} from 'modules/auth/store/actions';
import {useGetCurrentUser} from 'modules/auth/hooks/useIsAuthentificated';
import {useAuth0} from '@auth0/auth0-react';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/',
  },
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const user = useGetCurrentUser();
  const {logout: auth0logout} = useAuth0();
  const logout = () => {
    dispatch(destroySession());
    auth0logout();
  };

  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 220}}>
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle1" noWrap>
            {user?.name}
          </Typography>
          {/* <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {user?.email}
          </Typography> */}
        </Box>

        <Divider sx={{my: 1}} />

        {MENU_OPTIONS.map(option => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{typography: 'body2', py: 1, px: 2.5}}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{p: 2, pt: 1.5}}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Выйти
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
