import compose from 'lodash/fp/compose';
import {FC} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {WidgetIdProp, WidgetSelectorProps} from 'modules/widgets/types';

import {Icon} from '@iconify/react';
import EditFill from '@iconify/icons-eva/edit-2-fill';
import TrashIconFill from '@iconify/icons-eva/trash-2-fill';

import {adminApi} from 'api';
import {WidgetStructure} from '../WidgetStructure';
import Box from '@mui/material/Box';
import useDialog from 'components/Dialog';
import {Button, IconButton} from '@mui/material';
import {WidgetSelector} from '../WidgetSelector';

export const WidgetSelectorWithPreview: FC<
  Partial<WidgetIdProp & {onRemove: () => void}> & Pick<WidgetSelectorProps, 'onSelect'>
> = ({widgetId, onRemove, onSelect}) => {
  const {data} = adminApi.endpoints.widgetIndex.useQuery(widgetId ? {widgetId} : skipToken);
  const [Dialog, openDialog] = useDialog();
  const [SelectorDialog, openSelectorDialog, closeSelectorDialog] = useDialog();

  return (
    <>
      {!widgetId && (
        <Button onClick={openSelectorDialog} variant="contained" color={widgetId ? 'error' : undefined}>
          Установить виджет
        </Button>
      )}
      {widgetId && data && (
        <Box p={3} position="relative">
          {data.name}
          <Box position="absolute" sx={{top: 0, right: 0}}>
            {onRemove && (
              <IconButton onClick={onRemove}>
                <Icon icon={TrashIconFill} />
              </IconButton>
            )}
            <IconButton onClick={openDialog}>
              <Icon icon={EditFill} />
            </IconButton>
          </Box>
        </Box>
      )}
      {widgetId && (
        <Dialog title="Редактирование виджета" maxWidth="lg">
          <WidgetStructure widgetId={widgetId} />
        </Dialog>
      )}
      <SelectorDialog title="Выбор или создание виджета" maxWidth="lg">
        <WidgetSelector onSelect={compose(closeSelectorDialog, onSelect)} />
      </SelectorDialog>
    </>
  );
};
