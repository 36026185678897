import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {FC, useCallback} from 'react';
import {adminApi} from 'api';

import {Form} from 'components/Form';
import {FormResetFnProp} from 'components/Form/types';
import {WidgetAttrFormDto, WidgetAttrProp, WidgetIdProp} from 'modules/widgets/types';

import {fields, validation, initials as getInitials} from './fields';

export const WidgetAttributeForm: FC<WidgetIdProp & Partial<WidgetAttrProp & FormResetFnProp>> = ({
  attr,
  onReset,
  widgetId,
}) => {
  const [patch] = adminApi.endpoints.widgetAttrPatch.useMutation();
  const [add] = adminApi.endpoints.widgetAttrsAdd.useMutation();
  const [destroy] = adminApi.endpoints.widgetAttrDelete.useMutation();
  const onRemove = () => {
    if (attr && attr._id) destroy({widgetId, attrId: attr._id});
  };

  const onSubmit = useCallback(
    (data: WidgetAttrFormDto) => {
      if (attr) return patch({widgetId, attrId: `${attr._id}`, widgetAttrPartialDto: data});
      return add({
        widgetId,
        widgetAttrDto: {
          ...data,
          widgetId,
        },
      });
    },
    [widgetId, add, attr, patch]
  );

  return (
    <Form
      fields={fields(attr)}
      initials={getInitials(attr)}
      validation={validation}
      buttonsPosition={attr ? 'right' : 'bottom'}
      iconButtons={Boolean(attr)}
      onSubmit={onSubmit}
      buttonsStructure={[
        {type: 'submit', text: 'Сохранить', display: 'icon', icon: saveIcon},
        {type: 'reset', text: 'Сбросить', color: 'error', display: 'icon', icon: refreshIcon},
        attr
          ? {
              onClick: onRemove,
              text: 'Удалить',
              display: 'icon',
              icon: trashIcon,
              color: 'error',
            }
          : undefined,
      ]}
      onReset={onReset}
      sx={{width: '100%'}}
    />
  );
};
