import flatten from 'flat';
import {Provider} from 'jotai';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider, FieldValues, SubmitHandler} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import {ValidationErrorSchema} from 'api/generated/admin-api';
import {useConfirmDialog} from 'components/Dialog';

import {Fields} from './Field';
import {Buttons} from './Buttons';
import {FormSettings} from './types';
import {defaultValuesAtom} from './utils';

const extractErrorsFromResponse = (data: ValidationErrorSchema[]): any =>
  data.reduce(
    (acc, item) => ({
      ...acc,
      [item.property]:
        item.children && item.children.length
          ? extractErrorsFromResponse(item.children as ValidationErrorSchema[])
          : Object.values(item.constraints),
    }),
    {}
  );

export const Form = <T extends FieldValues>({
  fields = [],
  initials,
  onSubmit,
  onReset,
  validation,
  resetAfterSubmit,
  buttonsStructure,
  submitConfirmation,
  buttonsPosition = 'bottom',
  iconButtons,
  sx,
}: FormSettings<T>) => {
  const methods = useForm<T>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initials as any,
    resolver: !validation ? undefined : yupResolver(validation),
  });

  useUpdateEffect(() => {
    methods.reset(initials as any);
  }, [initials]);

  const {enqueueSnackbar} = useSnackbar();
  const onError = () => {
    enqueueSnackbar('Неправильно заполнена форма!', {autoHideDuration: 2000, variant: 'error'});
  };

  const submitHandler: SubmitHandler<T> = async data => {
    if (!methods.formState.isSubmitting) return;

    const result = await onSubmit(data as T);
    if (result && 'error' in result) {
      const error = (result.error as any)?.data as {status: number; message: string; data?: any};
      if (error.status === 400 && error.data) {
        const rawErrors = error.data as ValidationErrorSchema[];
        const errors = flatten(extractErrorsFromResponse(rawErrors), {safe: true}) as Record<string, string[]>;
        Object.keys(errors).forEach(key =>
          methods.setError(key as any, {type: 'validate', message: errors[key].join(', ')})
        );
      }
      enqueueSnackbar(`${error?.status} ${error?.message}` || 'Что-то пошло не так!', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar('Успех!', {autoHideDuration: 2000, variant: 'success'});
    }

    if (resetAfterSubmit && initials) {
      onReset?.();
      methods.reset(initials as any);
    }
  };
  const [SubmitConfirm, submitWithConfirm] = useConfirmDialog({
    text: submitConfirmation,
    onConfirm: submitHandler as any,
  });

  let direction = 'column';
  switch (buttonsPosition) {
    case 'right':
      direction = 'row';
      break;
    case 'bottom':
      direction = 'column';
      break;
    case 'left':
      direction = 'row-reverse';
      break;
    case 'top':
      direction = 'column-reverse';
      break;
  }

  return (
    <Provider initialValues={[[defaultValuesAtom, initials]]}>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={methods.handleSubmit(submitConfirmation ? submitWithConfirm : submitHandler, onError)}
        sx={sx}
      >
        <SubmitConfirm />
        <FormProvider {...methods}>
          <Stack spacing={2} direction={direction as any}>
            <Stack spacing={2} flexGrow={1}>
              <Fields fields={fields} />
            </Stack>
            {/* {Footer && Footer(state)} */}
            <Buttons buttonsStructure={buttonsStructure} iconButtons={iconButtons} onReset={onReset} />
          </Stack>
        </FormProvider>
      </Box>
    </Provider>
  );
};
