import {FC} from 'react';
import {DragDropContext, Draggable, Droppable, DropResult} from '@hello-pangea/dnd';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import DragHandle from '@mui/icons-material/DragHandle';

import {adminApi} from 'api';
import {WidgetIdProp} from 'modules/widgets/types';

import {WidgetAttributeForm} from '../WidgetAttributeForm';

export const WidgetAttributesList: FC<WidgetIdProp> = ({widgetId}) => {
  const [patchPositions] = adminApi.endpoints.widgetAttrsChangePositions.useMutation();
  const {data: attrs} = adminApi.endpoints.widgetAttrsIndex.useQuery({widgetId});
  if (!attrs) return null;

  const changePositions = (data: DropResult) => {
    if (data.destination) {
      const attrsId = attrs.map(i => `${i._id}`);
      const removed = attrsId.splice(data.source.index, 1);
      attrsId.splice(data.destination.index, 0, removed[0]);
      patchPositions({widgetId, widgetAttrsPositions: {attrsId}});
    }
  };
  return (
    <DragDropContext onDragEnd={changePositions}>
      <CardContent>
        <Droppable droppableId={`widget_attributes${widgetId}`}>
          {p => (
            <Stack spacing={1.5} ref={p.innerRef} {...p.droppableProps}>
              {attrs.map((attr, index) => (
                <Draggable key={attr._id} draggableId={`${attr._id}`} index={index}>
                  {p => (
                    <Stack direction="row" alignItems="center" spacing={1} ref={p.innerRef} {...p.draggableProps}>
                      <Box {...p.dragHandleProps}>
                        <Box component={DragHandle} color="grey.500" />
                      </Box>
                      <WidgetAttributeForm attr={attr} widgetId={widgetId} />
                    </Stack>
                  )}
                </Draggable>
              ))}
            </Stack>
          )}
        </Droppable>
      </CardContent>
    </DragDropContext>
  );
};
