import {useAuth0} from '@auth0/auth0-react';
import {FunctionComponent} from 'react';
import {Navigate, Outlet, useRoutes} from 'react-router';
// import {useMyId} from 'utils';
// import {Route, RouteComponentProps, Switch, useRouteMatch} from 'react-router-dom';
import {ModuleLayout} from '../../components';
import {AuthLogin} from '../../containers';

const AuthGuard = () => {
  const {isAuthenticated} = useAuth0();
  // const myId = useMyId();
  if (isAuthenticated) return <Navigate to="/app" />;
  return <Outlet />;
};

export const AuthLayout: FunctionComponent<{}> = () => {
  const routes = useRoutes([
    {
      element: <AuthGuard />,
      children: [
        {
          path: '/',
          element: <AuthLogin />,
        },
        {
          path: '/restore',
          element: <AuthLogin />,
        },
        {
          path: '/register',
          element: <AuthLogin />,
        },
      ],
    },
  ]);

  return <ModuleLayout>{routes}</ModuleLayout>;
};

export default AuthLayout;
