import {useParams} from 'react-router';
import {CommonPage} from 'components/ListPage';
import {WidgetStructure} from 'modules/widgets/containers';

export const WidgetPage = () => {
  const {widgetId} = useParams<'widgetId'>();

  if (!widgetId) return null;
  return <CommonPage maxWidth="xl">{widgetId && <WidgetStructure widgetId={widgetId} />}</CommonPage>;
};
