import {Auth0Client, Auth0ClientOptions} from '@auth0/auth0-spa-js';

export const auth0config: Auth0ClientOptions = {
  useRefreshTokens: true,
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  useRefreshTokensFallback: true,
  authorizationParams: {
    scope: 'openid offline_access',
    redirect_uri: `${window.location.origin}/app`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  },
};

export const Auth0 = new Auth0Client(auth0config);
