import {FC, useCallback} from 'react';

import {adminApi} from 'api';
import {WidgetIdProp, WidgetRuleFormDto, WidgetRuleProp} from 'modules/widgets/types';
import {FormResetFnProp} from 'components/Form/types';
import {Form} from 'components/Form';

import {fields, validation, initials} from './fields';

export const WidgetRuleForm: FC<WidgetIdProp & Partial<WidgetRuleProp & FormResetFnProp>> = ({
  widgetId,
  rule,
  onReset,
}) => {
  const [add] = adminApi.endpoints.widgetRulesAdd.useMutation();
  const [patch] = adminApi.endpoints.widgetRulePatch.useMutation();

  const onSubmit = useCallback(
    (data: WidgetRuleFormDto) => {
      if (rule) return patch({widgetId, ruleId: `${rule._id}`, widgetRulePartialDto: data});
      return add({widgetId, widgetRuleDto: {...data, widgetId}});
    },
    [widgetId, add, patch, rule]
  );

  return (
    <>
      <Form
        fields={fields(Boolean(rule))}
        validation={validation}
        initials={initials(rule)}
        onSubmit={onSubmit}
        onReset={onReset}
        buttonsPosition={rule ? 'right' : 'bottom'}
        iconButtons={Boolean(rule)}
      />
    </>
  );
};
