import {useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridColDef, GridToolbar} from '@mui/x-data-grid';

import {getRowId} from 'utils';
import {APIKeys, EndpointName} from 'utils/api/types';

import {useList, useIsQueryExists} from './hook';
import {DataListProps, DataListProviderProps} from './types';

function DataList<Query, Result>({
  sx,
  debug,
  params,
  columns,
  getItems,
  onSelect,
  useFetch,
  selection,
  hideToolbar,
  onRowClick,
  disableLimit,
  checkboxSelection,
  hideFooterPagination,
  Toolbar,
}: DataListProps<Query, Result>) {
  const [pageSize, setPageSize] = useState(10);
  const {data, isLoading, isFetching} = useList<Query, Result>({useFetch, params, debug, getItems, disableLimit});

  return (
    <Box display="flex" height="100%" sx={sx}>
      <Box flexGrow={1} justifyContent="center" alignItems="flex-start">
        <DataGrid
          rows={data}
          autoHeight
          getRowId={getRowId}
          onRowClick={onRowClick}
          loading={isLoading || isFetching}
          pageSize={pageSize}
          hideFooterPagination={hideFooterPagination}
          onPageSizeChange={pageSize => setPageSize(pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          selectionModel={isLoading || isFetching ? undefined : selection}
          checkboxSelection={checkboxSelection ?? !!onSelect}
          onSelectionModelChange={onSelect}
          columns={columns.filter(Boolean) as GridColDef[]}
          components={{Toolbar: hideToolbar ? undefined : Toolbar || GridToolbar}}
          disableSelectionOnClick={Boolean(onRowClick)}
          sx={{
            '& .MuiDataGrid-toolbarContainer': {
              paddingBottom: 0,
              backgroundColor: 'background.paper',
              flexDirection: 'row-reverse',
            },
          }}
        />
      </Box>
    </Box>
  );
}

function DataListProvider<Key extends APIKeys, Endpoint extends EndpointName<Key>>({
  api,
  queryName,
  ...props
}: DataListProviderProps<Key, Endpoint>) {
  const useFetch = useIsQueryExists(api, queryName);
  if (!useFetch) return null;
  return <DataList useFetch={useFetch as any} {...props} />;
}

export default DataListProvider;
