import {FC, useState} from 'react';
import {Tabs, Tab} from '@mui/material';

import {DataList} from 'components/DataList';
import {widgetsColumns} from 'modules/widgets/columns';
import {WidgetSelectorProps} from 'modules/widgets/types';
import {WidgetForm} from '../WidgetForm';

export const WidgetSelector: FC<WidgetSelectorProps> = ({onSelect, onCancel}) => {
  const [state, setState] = useState<'new' | 'select'>('select');

  return (
    <>
      <Tabs value={state} onChange={(e, newTab: 'new' | 'select') => setState(newTab)}>
        <Tab label="Выбрать из списка" value="select" />
        <Tab label="Создать новый" value="new" />
      </Tabs>
      {state === 'select' && (
        <DataList
          api="admin"
          queryName="widgetsIndex"
          columns={widgetsColumns()}
          onRowClick={({row}: any) => onSelect(`${row._id}`)}
        />
      )}
      {state === 'new' && <WidgetForm onReset={onCancel} onSuccess={onSelect} />}
    </>
  );
};
