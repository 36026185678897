import {Auth0, auth0config} from 'modules/auth/utils';

export const prepareHeaders = async (headers: Headers) => {
  try {
    const token = await Auth0.getTokenSilently({
      authorizationParams: auth0config.authorizationParams,
    });
    if (token) headers.set('Authorization', `Bearer ${token}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e.message);
  }
  return headers;
};

export default prepareHeaders;
