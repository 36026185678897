import {FC} from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {CardContent} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';

import {WidgetIdProp} from 'modules/widgets/types';
import {useDialogForm} from 'components/DialogForm';

import {WidgetForm} from '../WidgetForm';
import {WidgetRuleForm, WidgetRulesList} from '../WidgetRules';
import {WidgetAttributeCreateForTemplate, WidgetAttributesList} from '../WidgetAttrs';

export const WidgetStructure: FC<WidgetIdProp> = ({widgetId}) => {
  const [RuleFormContainer, createRule] = useDialogForm({entityName: 'widgetRules'});
  const [CreateAttsFromTemplate, createAttrsFromTemplate] = useDialogForm({entityName: 'widgetAttrsFromTemplate'});

  return (
    <Grid container gap={3}>
      <Grid item xs={12} component={Card}>
        <CardContent>
          <WidgetForm widgetId={widgetId} />
        </CardContent>
      </Grid>
      <Grid item xs={12} component={Card}>
        <CardHeader title="Аттрибуты" action={<Button onClick={createAttrsFromTemplate}>Добавить аттрибуты</Button>} />
        <CreateAttsFromTemplate
          Form={({onClose}) => <WidgetAttributeCreateForTemplate widgetId={widgetId} onReset={onClose} />}
        />
        <WidgetAttributesList widgetId={widgetId} />
      </Grid>

      <Grid item xs={12} component={Card}>
        <CardHeader title="Правила" action={<Button onClick={createRule}>Добавить правило</Button>} />
        <WidgetRulesList widgetId={widgetId} />
        <RuleFormContainer Form={({onClose}) => <WidgetRuleForm widgetId={widgetId} onReset={onClose} />} />
      </Grid>
    </Grid>
  );
};
