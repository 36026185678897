import {createApi} from '@reduxjs/toolkit/query/react';
import {default as customBaseQuery} from '../queries/gateway';
export const api = createApi({
  reducerPath: 'gateway',
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: build => ({
    postSmsSend: build.mutation<PostSmsSendApiResponse, PostSmsSendApiArg>({
      query: () => ({url: `/sms/send`, method: 'POST'}),
    }),
    profileIndex: build.query<ProfileIndexApiResponse, ProfileIndexApiArg>({
      query: () => ({url: `/api/profile`}),
    }),
    profileUpdate: build.mutation<ProfileUpdateApiResponse, ProfileUpdateApiArg>({
      query: queryArg => ({
        url: `/api/profile`,
        method: 'POST',
        body: queryArg.userProfileDto,
      }),
    }),
    profileTagsIndex: build.query<ProfileTagsIndexApiResponse, ProfileTagsIndexApiArg>({
      query: () => ({url: `/api/profile/tags`}),
    }),
    profileTagsAdd: build.mutation<ProfileTagsAddApiResponse, ProfileTagsAddApiArg>({
      query: queryArg => ({
        url: `/api/profile/tags`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    profileStreamsIndex: build.query<ProfileStreamsIndexApiResponse, ProfileStreamsIndexApiArg>({
      query: () => ({url: `/api/profile/streams`}),
    }),
    profileStreamsAdd: build.mutation<ProfileStreamsAddApiResponse, ProfileStreamsAddApiArg>({
      query: queryArg => ({
        url: `/api/profile/streams/stream_${queryArg.streamId}/assign`,
        method: 'POST',
      }),
    }),
    streamsIndex: build.query<StreamsIndexApiResponse, StreamsIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
        },
      }),
    }),
    streamsAdd: build.mutation<StreamsAddApiResponse, StreamsAddApiArg>({
      query: queryArg => ({
        url: `/api/streams`,
        method: 'POST',
        body: queryArg.streamDto,
      }),
    }),
    streamsGetMushroom: build.query<StreamsGetMushroomApiResponse, StreamsGetMushroomApiArg>({
      query: () => ({url: `/api/streams/mushroom`}),
    }),
    streamIndex: build.query<StreamIndexApiResponse, StreamIndexApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}`,
      }),
    }),
    streamPatch: build.mutation<StreamPatchApiResponse, StreamPatchApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}`,
        method: 'PATCH',
        body: queryArg.streamDto,
      }),
    }),
    streamUsers: build.query<StreamUsersApiResponse, StreamUsersApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/users`,
      }),
    }),
    streamAddUsers: build.mutation<StreamAddUsersApiResponse, StreamAddUsersApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/users`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    streamDeleteUser: build.mutation<StreamDeleteUserApiResponse, StreamDeleteUserApiArg>({
      query: queryArg => ({
        url: `/api/streams/stream_${queryArg.streamId}/user_${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    tagsIndex: build.query<TagsIndexApiResponse, TagsIndexApiArg>({
      query: queryArg => ({
        url: `/api/tags`,
        params: {
          offset: queryArg.offset,
          limit: queryArg.limit,
          skip_id: queryArg.skipId,
          _id: queryArg._id,
          name: queryArg.name,
          type: queryArg['type'],
        },
      }),
    }),
    tagsAdd: build.mutation<TagsAddApiResponse, TagsAddApiArg>({
      query: queryArg => ({
        url: `/api/tags`,
        method: 'POST',
        body: queryArg.tagDto,
      }),
    }),
    tagIndex: build.query<TagIndexApiResponse, TagIndexApiArg>({
      query: queryArg => ({url: `/api/tags/tag_${queryArg.tagId}`}),
    }),
    tagPatch: build.mutation<TagPatchApiResponse, TagPatchApiArg>({
      query: queryArg => ({
        url: `/api/tags/tag_${queryArg.tagId}`,
        method: 'PATCH',
        body: queryArg.tagDto,
      }),
    }),
  }),
});
export type PostSmsSendApiResponse = unknown;
export type PostSmsSendApiArg = void;
export type ProfileIndexApiResponse = /** status 200 Информация о пользователе */ UserInfo;
export type ProfileIndexApiArg = void;
export type ProfileUpdateApiResponse = /** status 200 Информация о пользователе */ UserInfo;
export type ProfileUpdateApiArg = {
  userProfileDto: UserProfileDto;
};
export type ProfileTagsIndexApiResponse = /** status 200 Потоки пользователя */ Tags[];
export type ProfileTagsIndexApiArg = void;
export type ProfileTagsAddApiResponse = /** status 200 Потоки пользователя */ Tags[];
export type ProfileTagsAddApiArg = {
  body: string[];
};
export type ProfileStreamsIndexApiResponse = /** status 200 Потоки пользователя */ Streams[];
export type ProfileStreamsIndexApiArg = void;
export type ProfileStreamsAddApiResponse = /** status 200 Потоки пользователя */ Streams[];
export type ProfileStreamsAddApiArg = {
  /** The ID of the stream to assign */
  streamId: string;
};
export type StreamsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  data?: Streams[];
  message?: string;
};
export type StreamsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
};
export type StreamsAddApiResponse = /** status 200 Информация о пользователе */ Streams;
export type StreamsAddApiArg = {
  streamDto: StreamDto;
};
export type StreamsGetMushroomApiResponse = /** status 200 Информация о пользователе */ Streams;
export type StreamsGetMushroomApiArg = void;
export type StreamIndexApiResponse = /** status 200 Информация о пользователе */ Streams;
export type StreamIndexApiArg = {
  /** ID of the getting Stream */
  streamId: string;
};
export type StreamPatchApiResponse = /** status 200 Информация о пользователе */ Streams;
export type StreamPatchApiArg = {
  /** ID of the updating Stream */
  streamId: string;
  streamDto: StreamDto;
};
export type StreamUsersApiResponse = /** status 200 Потоки пользователя */ StreamsUsers[];
export type StreamUsersApiArg = {
  /** The ID of the stream delete users from */
  streamId: string;
};
export type StreamAddUsersApiResponse = /** status 200 Потоки пользователя */ StreamsUsers[];
export type StreamAddUsersApiArg = {
  /** The ID of the stream to assign */
  streamId: string;
  body: {
    usersId?: string[];
  };
};
export type StreamDeleteUserApiResponse = /** status 200 Потоки пользователя */ StreamsUsers[];
export type StreamDeleteUserApiArg = {
  /** The ID of the stream to delete user from */
  streamId: string;
  /** The ID of the user to delete from stream */
  userId: string;
};
export type TagsIndexApiResponse = /** status 200 Структура со сводной информацией */ {
  total?: number;
  data?: Tags[];
};
export type TagsIndexApiArg = {
  /** Смещение */
  offset?: number;
  /** Ограничение количества */
  limit?: number;
  /** Пропускаемый идентификатор */
  skipId?: string[];
  /** Идентификатор */
  _id?: string[];
  /** Имя тега */
  name?: string;
  /** Тип тега */
  type?: string;
};
export type TagsAddApiResponse = /** status 200 Информация о пользователе */ Tags;
export type TagsAddApiArg = {
  tagDto: TagDto;
};
export type TagIndexApiResponse = /** status 200 Информация о теге */ Tags;
export type TagIndexApiArg = {
  /** Идентификатор */
  tagId: string;
};
export type TagPatchApiResponse = /** status 200 Информация о теге */ Tags;
export type TagPatchApiArg = {
  /** Идентификатор */
  tagId: string;
  tagDto: TagDto;
};
export type Files = {
  s3?: boolean;
  relationType?: 'preview' | 'jpg' | 'crop';
  relationParams?: string;
  relationId?: string;
  type: string;
  userId?: string;
  path: string;
  createdAt?: string;
  deletedAt?: string;
  size: number;
  name: string;
  location?: string;
  _id?: string;
  updatedAt?: string;
};
export type StreamDesignOptions = {
  welcomeScreenTitle?: string;
  createdAt?: string;
  startButtonText?: string;
  deletedAt?: string;
  _id?: string;
  welcomeScreenText?: string;
  rulesText?: string;
  introductionVideoLink?: string;
  updatedAt?: string;
};
export type Streams = {
  isRegistrationOpen: boolean;
  defaultAdventureId?: string;
  description?: string;
  enabled: boolean;
  isFreeMembership: boolean;
  streamDesignOptions?: StreamDesignOptions;
  createdAt?: string;
  deletedAt?: string;
  isMushroomStream: boolean;
  name: string;
  freeMembershipDeadlineSeconds?: number;
  _id?: string;
  defaultQuestId?: string;
  updatedAt?: string;
};
export type GroupsStats = {
  goalsCount: number;
  scoresCount: number;
  publicationsCount: number;
  subscribersCount: number;
  totalRating: number;
  updatedDate: string | string;
  teammatesCount: number;
  scoresPercent: number;
  likesPercent: number;
  NPS: number;
  goalsCompletedCount: number;
  likesCount: number;
  NPSRating: number;
};
export type GroupsRatingPosition = {
  totalPlaces: number;
  byNPS: number;
  updatedDate: string | string;
  byTotal: number;
};
export type Groups = {
  short_description?: string;
  isDismissed?: boolean;
  membersCount?: number;
  streamId?: string;
  externalLink?: string;
  ident?: number;
  requestsIsActive: boolean;
  description?: string;
  enabled: boolean;
  createdAt?: string;
  membersLimit?: number;
  dismissReason?: string;
  deletedAt?: string;
  file?: Files;
  stream?: Streams;
  stats?: GroupsStats;
  name: string;
  _id?: string;
  fileId?: string;
  ratingPosition?: GroupsRatingPosition;
  updatedAt?: string;
};
export type UsersStats = {
  groupsId: string[];
  subscribesCount: number;
  goalsCount: number;
  scoresCount: number;
  jobsCount: number;
  publicationsCount: number;
  streams: Groups[];
  subscribersCount: number;
  groups: Groups[];
  totalRating: number;
  updatedDate: string | string;
  experience: number;
  teammatesCount: number;
  scoresPercent: number;
  likesPercent: number;
  NPS: number;
  goalsCompletedCount: number;
  likesCount: number;
  commentsCount: number;
  tasksCount: number;
  NPSRating: number;
  experiencePower: number;
  streamsId: string[];
  jobsCompletedCount: number;
};
export type UsersRatingPosition = {
  totalPlaces: number;
  byExperience: number;
  byNPS: number;
  updatedDate: string | string;
  byTotal: number;
};
export type GroupsUsers = {
  exitReason?: string;
  isLeader?: boolean;
  groupId: string;
  title?: string;
  userId: string;
  createdAt?: string;
  deletedAt?: string;
  joinDate: string | string;
  _id?: string;
  user?: Users;
  exitDate: string | string;
  group?: Groups;
  updatedAt?: string;
};
export type UsersLogins = {
  createdAt?: string;
  deletedAt?: string;
  _id?: string;
  type: 'plain' | 'phone' | 'email';
  userId?: string;
  value: string;
  enabled: boolean;
  updatedAt?: string;
};
export type ClaimsStats = {
  claimedDate?: string | string;
  count?: number;
  updatedDate?: string | string;
  isClaimed?: boolean;
};
export type UsersStatus = {
  lastSeen: number;
  isOnline: number;
  updatedDate: string | string;
};
export type Users = {
  gender?: 'male' | 'female' | 'not_sure';
  city?: string;
  externalLink?: string;
  ident?: number;
  isMySubscriber?: number;
  meIsSubscriber?: number;
  description?: string;
  enabled: boolean;
  createdAt?: string;
  file?: Files;
  backgroundFileId?: string | null;
  stats?: UsersStats;
  surname?: string;
  disclaimer?: string;
  ratingPosition?: UsersRatingPosition;
  updatedAt?: string;
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  middlename?: string;
  groups?: GroupsUsers[];
  isNewUser?: boolean;
  birthDate?: string | string;
  userLogin?: UsersLogins;
  deletedAt?: string;
  name: string;
  claims?: ClaimsStats;
  _id?: string;
  logins?: UsersLogins[];
  fileId?: string;
  status?: UsersStatus;
};
export type UserInfo = {
  isTeammate: boolean;
  isSubscribed: boolean;
  isMe: boolean;
  isSubscriber: boolean;
  account: Users;
  username?: string;
};
export type ErrorMessage = {
  data?: object;
  message: string;
  status: number;
};
export type ValidationErrorSchema = {
  children?: object[];
  property: string;
  value?: any;
  constraints: object;
  target?: object;
};
export type Date = string;
export type Datetime = string;
export type UserProfileDto = {
  gender?: 'male' | 'female' | 'not_sure';
  relationStatus?:
    | 'single'
    | 'in_relation'
    | 'engaged'
    | 'married'
    | 'civil_union'
    | 'in_love'
    | 'complicated'
    | 'in_search';
  city?: string;
  externalLink?: string;
  middlename?: string;
  description?: string;
  isNewUser?: boolean;
  birthDate?: Date | Datetime;
  backgroundFileId?: string | null;
  surname?: string;
  name: string;
  disclaimer?: string;
  username?: string;
};
export type TagType = 'default' | 'personal_quality' | 'hashtag' | 'quest' | 'pain';
export type Tags = {
  name?: string;
  _id?: string;
  type?: TagType;
  enabled?: boolean;
};
export type StreamDesignOptionsDto = {
  welcomeScreenTitle?: string;
  startButtonText?: string;
  welcomeScreenText?: string;
  rulesText?: string;
  introductionVideoLink?: string;
};
export type StreamDto = {
  isRegistrationOpen?: boolean;
  streamDesignOptions?: StreamDesignOptionsDto;
  defaultAdventureId?: string;
  isMushroomStream?: boolean;
  name: string;
  description?: string;
  freeMembershipDeadlineSeconds?: number;
  defaultQuestId?: string;
  isFreeMembership?: boolean;
  enabled: boolean;
};
export type StreamsUsers = {
  createdAt?: string;
  deletedAt?: string;
  joinDate: string | string;
  streamId: string;
  _id?: string;
  userId: string;
  user?: Users;
  exitDate: string | string;
  updatedAt?: string;
};
export type TagDto = {
  name?: string;
  type?: TagType;
};
export const {
  usePostSmsSendMutation,
  useProfileIndexQuery,
  useProfileUpdateMutation,
  useProfileTagsIndexQuery,
  useProfileTagsAddMutation,
  useProfileStreamsIndexQuery,
  useProfileStreamsAddMutation,
  useStreamsIndexQuery,
  useStreamsAddMutation,
  useStreamsGetMushroomQuery,
  useStreamIndexQuery,
  useStreamPatchMutation,
  useStreamUsersQuery,
  useStreamAddUsersMutation,
  useStreamDeleteUserMutation,
  useTagsIndexQuery,
  useTagsAddMutation,
  useTagIndexQuery,
  useTagPatchMutation,
} = api;
