import pick from 'lodash/pick';

import {Icon} from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-outline';
import saveIcon from '@iconify/icons-eva/save-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import refreshIcon from '@iconify/icons-eva/refresh-outline';

import {FC, useCallback} from 'react';

import {adminApi} from 'api';
import {WidgetAttrsIndexApiArg} from 'api/generated/admin-api';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {skipToken} from '@reduxjs/toolkit/dist/query';

import {Form} from 'components/Form';
import {FormResetFnProp} from 'components/Form/types';
import {DataList} from 'components/DataList';

import {WidgetIdProp} from 'modules/widgets/types';
import {widgetAttrsColumns} from 'modules/widgets/columns';
import {WidgetVisualPropProp, WidgetVisualIdProp, WidgetVisualPropFormDto} from 'modules/widgets/types';

import {fields, validation, initials as getInitials} from './fields';

const WidgetAttrs: FC<WidgetIdProp & WidgetVisualIdProp & Pick<WidgetAttrsIndexApiArg, 'skipId'>> = ({
  widgetId,
  skipId,
  visualId,
}) => {
  const {data: widget} = adminApi.endpoints.widgetIndex.useQuery({widgetId});
  const {data: attributes} = adminApi.endpoints.widgetAttrsIndex.useQuery({widgetId, skipId});
  const [add] = adminApi.endpoints.widgetsVisualPropsAdd.useMutation();
  const addProp = useCallback(
    (widgetAttrId: string) => {
      add({
        visualId,
        widgetVisualPropDto: {widgetAttrId, widgetId},
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visualId, add]
  );

  if (!attributes?.length) return null;
  return (
    <>
      {widget?.name && <Typography>{widget?.name}</Typography>}
      <DataList
        api="admin"
        hideToolbar
        hideFooterPagination
        queryName="widgetAttrsIndex"
        params={{widgetId, skipId}}
        onRowClick={({row}) => addProp((row as any)._id as string)}
        columns={widgetAttrsColumns(undefined, {hideEnabled: true}, ({row}) => [
          {label: 'Добавить', icon: <Icon icon={plusIcon} />, onClick: () => addProp(row._id as string)},
        ])}
      />
    </>
  );
};

export const WidgetVisualsPropsAdd: FC<
  {widgetsId: string[]} & WidgetVisualIdProp & Partial<WidgetVisualPropProp & FormResetFnProp>
> = ({visualId, widgetsId}) => {
  const {data: props} = adminApi.endpoints.widgetsVisualPropsIndex.useQuery({visualId});
  const usedAttributesIds = props?.map(item => item.widgetAttrId);

  return (
    <Stack spacing={1}>
      {widgetsId.map(widgetId => (
        <WidgetAttrs key={widgetId} widgetId={widgetId} skipId={usedAttributesIds} visualId={visualId} />
      ))}
    </Stack>
  );
};

export const WidgetVisualsPropForm: FC<
  {widgetsId: string[]} & WidgetVisualIdProp & Partial<WidgetVisualPropProp & FormResetFnProp>
> = ({prop, onReset, visualId}) => {
  const [patch] = adminApi.endpoints.widgetsVisualPropPatch.useMutation();
  const [add] = adminApi.endpoints.widgetsVisualPropsAdd.useMutation();
  const [destroy] = adminApi.endpoints.widgetsVisualPropDelete.useMutation();
  const onRemove = () => {
    if (prop?._id) destroy({visualId, propId: prop._id});
  };
  const {data: atribute} = adminApi.endpoints.widgetAttrIndex.useQuery(
    prop?.widgetAttrId && prop?.widgetId ? {attrId: prop.widgetAttrId, widgetId: prop.widgetId} : skipToken
  );

  const onSubmit = useCallback(
    (dataRaw: WidgetVisualPropFormDto) => {
      if (!prop) return null;
      return patch({
        visualId,
        propId: `${prop._id}`,
        widgetVisualPropPartialDto: {
          ...pick(prop, ['widgetId', 'widgetAttrId', 'deletedAt']),
          custom: dataRaw.custom ? JSON.parse(dataRaw.custom) : prop?.custom,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [visualId, add, prop, patch]
  );

  return (
    <Stack spacing={2} my={1} width={1}>
      <Typography variant="body1">{atribute?.name}</Typography>
      <Box width={1}>
        <Form
          fields={fields}
          initials={getInitials(prop)}
          validation={validation}
          buttonsPosition={prop ? 'right' : 'bottom'}
          iconButtons={Boolean(prop)}
          onSubmit={onSubmit}
          onReset={onReset}
          buttonsStructure={[
            {type: 'submit', text: 'Сохранить', display: 'icon', icon: saveIcon},
            {type: 'reset', text: 'Сбросить', color: 'error', display: 'icon', icon: refreshIcon},
            prop
              ? {
                  onClick: onRemove,
                  text: 'Удалить',
                  display: 'icon',
                  icon: trashIcon,
                  color: 'error',
                }
              : undefined,
          ]}
          sx={{width: '100%'}}
        />
      </Box>
    </Stack>
  );
};
