import {capitalCase} from 'change-case';

// material
import {styled} from '@mui/material/styles';
import {Box, Card, Stack, Link, Tooltip, Container, Typography, Button} from '@mui/material';
// routes
// hooks
import useAuth from '__theme/hooks/useAuth';
// layouts
import AuthLayout from '__theme/layouts/AuthLayout';
// components
import Page from '__theme/components/Page';
import {MHidden} from '__theme/components/@material-extend';
import {useAuth0} from '@auth0/auth0-react';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const {method} = useAuth();
  const {loginWithRedirect} = useAuth0();

  return (
    <RootStyle title="Login | Minimal-UI">
      <AuthLayout>
        Нет аккаунта?&nbsp;
        <Link underline="none" variant="subtitle2">
          Бывает :(
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
            Привет, друг!
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{mb: 5}}>
            <Box sx={{flexGrow: 1}}>
              <Typography variant="h4" gutterBottom>
                Вход в управление игрой
              </Typography>
              <Typography sx={{color: 'text.secondary'}}>Введи логин и пароль.</Typography>
            </Box>

            <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{width: 32, height: 32}} />
            </Tooltip>
          </Stack>

          {/* <Alert severity="info" sx={{mb: 3}}>
            Use email : <strong>demo@minimals.cc</strong> / password :<strong>&nbsp;demo1234</strong>
          </Alert> */}
          {/* <LoginBlock /> */}
          <Button variant="contained" onClick={() => loginWithRedirect()}>
            Войти
          </Button>

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{mt: 3}}>
              Нет аккаунта?&nbsp;
              <Link variant="subtitle2">Бывает :(</Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
