import {lazy} from 'react';
import {Navigate, RouteObject} from 'react-router';

import {PATH_APP} from 'router/routes';

import {AccessGuard} from 'modules/auth/containers';
import IsExistsContainer from 'components/IsExistsContainer';
import {BreadcrumbsSet} from 'components/Breadcrumbs/BreadcrumbsSet';

import {useGetContent} from './pages/ContentItem/hook';
import {Contents} from 'api/generated/admin-api';

const ContentListPage = lazy(() => import('./pages/ContentList'));
const ContentItemPage = lazy(() => import('./pages/ContentItem'));

const router: RouteObject = {
  path: 'contents',
  element: (
    <AccessGuard endpoint="contentsIndex">
      <BreadcrumbsSet name="Материалы" href={PATH_APP.tasks.contents.root} />
    </AccessGuard>
  ),
  children: [
    {index: true, element: <Navigate to="./list" replace />},
    {
      path: 'list',
      children: [
        {
          index: true,
          element: <ContentListPage />,
        },
        {
          path: 'create',
          element: (
            <AccessGuard endpoint="contentsAdd">
              <BreadcrumbsSet name="Новый материал" />
            </AccessGuard>
          ),
          children: [{index: true, element: <ContentItemPage />}],
        },
        {
          path: ':contentId',
          element: (
            <AccessGuard endpoint="contentIndex">
              <IsExistsContainer<Contents> useFetch={useGetContent}>
                {content => <BreadcrumbsSet name={content.name || 'Материал без названия'} />}
              </IsExistsContainer>
            </AccessGuard>
          ),
          children: [
            {
              index: true,
              element: <ContentItemPage />,
            },
          ],
        },
      ],
    },
  ],
};

export default router;
