import {last} from 'lodash';
import {useToggle} from 'react-use';
import {useCallback, useState} from 'react';
import {Button, Dialog, Stack, DialogContent, DialogTitle} from '@mui/material';

import {DataList} from 'components/DataList';
import {APIKeys, EndpointName} from 'utils/api/types';
import {DialogSelectorContainerProps} from './types';

const InnerContainer = <Key extends APIKeys, Endpoint extends EndpointName<Key>>({
  api,
  title,
  params,
  columns,
  onClose,
  onConfirm,
  queryName,
  disableLimit,
  getItems,
  single = false,
  initialSelection = [],
}: DialogSelectorContainerProps<Key, Endpoint>) => {
  const [selectedIds, setSelected] = useState<(string | number)[]>(initialSelection);
  const onSelect = useCallback(
    (ids: (string | number)[]) => {
      setSelected(single ? [last(ids) as string | number] : ids);
    },
    [setSelected, single]
  );
  const confirmHandler = useCallback(async () => {
    await onConfirm(selectedIds.map(item => item.toString()));
    if (onClose) onClose();
  }, [selectedIds, onConfirm, onClose]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          {title}
          <Button variant="contained" size="small" onClick={confirmHandler}>
            Подтвердить выбор
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{p: 0}} dividers>
        <DataList
          api={api}
          params={params}
          getItems={getItems}
          columns={columns}
          onSelect={onSelect}
          queryName={queryName}
          selection={selectedIds}
          disableLimit={disableLimit}
        />
      </DialogContent>
    </>
  );
};

export function useDialogSelector() {
  const [open, toggleOpen] = useToggle(false);
  const closeDialog = useCallback(() => toggleOpen(false), [toggleOpen]);
  const openSelector = useCallback(() => toggleOpen(true), [toggleOpen]);

  const Selector = useCallback(
    <Key extends APIKeys, Endpoint extends EndpointName<Key>>(props: DialogSelectorContainerProps<Key, Endpoint>) => (
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
        <InnerContainer {...props} onClose={closeDialog} />
      </Dialog>
    ),
    [closeDialog, open]
  );

  return {Selector, openSelector};
}

export default useDialogSelector;
